export { default as ClipboardCheck } from './ClipboardCheck'
export { default as Clipboard } from './Clipboard'
export { default as File02 } from './File02'
export { default as FileArrow01 } from './FileArrow01'
export { default as FileCheck02 } from './FileCheck02'
export { default as FileDownload02 } from './FileDownload02'
export { default as FilePlus01 } from './FilePlus01'
export { default as FilePlus02 } from './FilePlus02'
export { default as FileText } from './FileText'
export { default as FileUpload } from './FileUpload'
export { default as Folder } from './Folder'
