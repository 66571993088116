/*
* Formats a number with comma separators.
 formatNumber(1234567) will return '1,234,567'
 formatNumber(1234567.89) will return '1,234,567.89'
*/
export const formatNumber = (num: number | string) => {
  if (!num)
    return num
  const parts = num.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const formatFileSize = (num: number) => {
  if (!num)
    return num
  const units = ['', 'K', 'M', 'G', 'T', 'P']
  let index = 0
  while (num >= 1024 && index < units.length) {
    num = num / 1024
    index++
  }
  return `${num.toFixed(2)}${units[index]}B`
}

export const formatTime = (num: number) => {
  if (!num)
    return num
  const units = ['sec', 'min', 'h']
  let index = 0
  while (num >= 60 && index < units.length) {
    num = num / 60
    index++
  }
  return `${num.toFixed(2)} ${units[index]}`
}

export const getFileSize = (size: number) => {
  if (size / 1024 < 10)
    return `${(size / 1024).toFixed(2)}KB`

  return `${(size / 1024 / 1024).toFixed(2)}MB`
}

export const addReferenceToUrl = (url: string) => {
  try {
    const urlObj = new URL(url)
    urlObj.searchParams.set('reference', 'x-elephant')
    return urlObj.toString()
  }
  catch (e) {
    return url
  }
}
